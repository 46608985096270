import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../translations";

const propTypes = {
  policies: PropTypes.arrayOf(
    // TODO Chris - Need to extract into a policyShape within a common shapes folder
    PropTypes.shape({
      number: PropTypes.string,
      name: PropTypes.string,
      policy_type: PropTypes.string.isRequired
    }).isRequired
  )
};

const defaultProps = {
  policies: []
};

const AutomaticPaymentsPolicy = ({ policies }) => {
  return (
    <div className="auto-pay-policy">
      <table className="auto-pay-policy-table">
        <thead>
          <tr>
            <th>Policy</th>
            <th>Policy Type</th>
          </tr>
        </thead>
        {policies.map(policy => (
          <tbody key={policy.number}>
            <tr>
              <td>{policy.name || policy.number}</td>
              <td>
                {Translations.policy_types[policy.policy_type] ??
                  policy.policy_type}
              </td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
};

AutomaticPaymentsPolicy.propTypes = propTypes;
AutomaticPaymentsPolicy.defaultProps = defaultProps;

export default AutomaticPaymentsPolicy;
