import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ContentHeader, SelectNonForm, formatDate } from "@ufginsurance/ui-kit";
import { Translations } from "../components/translations";
import PolicyInfo from "../components/my-policy/PolicyInfo";
import { logger } from "../logging";
import { AnalyticActions } from "../constants/analytics";
import DocumentLink from "../components/account-summary/DocumentLink";
import PolicyActivityContainer from "../components/my-policy/PolicyActivityContainer";
import { fetchPolicyActivity } from "../actions/policyActivityActions";
import {
  fetchMyPoliciesList,
  fetchPolicyDecs
} from "../actions/myPolicyActions";
import LoadingIndicator from "../components/loading-indicator/LoadingIndicator";
import LinesOfBusiness from "../components/my-policy/LinesOfBusiness";
import CustomErrorView from "../components/CustomErrorView";
import HelpDeskMessage from "../help-desk/HelpDeskMessage";

const FIVE_MINUTES = 1000 * 60 * 5;

const enableViewAllFormsButton =
  window.env.REACT_APP_ENABLE_MY_POLICY_VIEW_ALL_FORMS;

const MyPolicyContainer = ({
  myPolicyData,
  fetchMyPoliciesList,
  fetchPolicyDecs,
  fetchPolicyActivity,
  match
}) => {
  const hasPolicies = policies => policies && policies.length > 0;

  const { policies, isLoading, coverageOptionsData, error } = myPolicyData;
  const [selectedPolicyNumber, setSelectedPolicyNumber] = useState("");
  const [fetchPolicyDecsInitially, setFetchPolicyDecsInitially] =
    useState(false);
  const [enablePolicyDropdown, setEnablePolicyDropdown] = useState(true);

  const trackAnalytics = evt => {
    logger.event({
      category: AnalyticActions.policy.view_all_forms,
      action: evt.target.textContent
    });
  };

  const selectedPolicy = useMemo(() => {
    return (
      hasPolicies(policies) &&
      policies.find(policy => policy.number === selectedPolicyNumber)
    );
  }, [policies, selectedPolicyNumber]);

  const showLargePolicyMessage = useMemo(() => {
    if (!selectedPolicy || !coverageOptionsData) {
      return false;
    }

    const coverages = coverageOptionsData[selectedPolicy.number];

    return (
      selectedPolicy.type.includes("commercial") &&
      coverages &&
      coverages.length > 1
    );
  }, [coverageOptionsData, selectedPolicy]);

  const policyOptions = useMemo(() => {
    return policies?.length
      ? policies.map(policy => {
          const policyType =
            Translations.policy_types[policy.type] ?? policy.type;
          return {
            value: policy.number,
            label: `${policyType} - ${policy.number}${
              !policy?.nickname ? "" : ` - ${policy.nickname}`
            }`
          };
        })
      : [];
  }, [policies]);

  const handleDropDownChange = ({ value }) => {
    setSelectedPolicyNumber(value);

    fetchPolicyActivity(value);

    const filteredPolicies =
      policies && policies.length > 0
        ? policies.filter(policy => policy.number === value)
        : null;
    if (
      filteredPolicies &&
      filteredPolicies.length > 0 &&
      filteredPolicies[0].origin === "ufg_core" &&
      ((value && !coverageOptionsData) ||
        (value && coverageOptionsData && !coverageOptionsData[value]))
    ) {
      const { company_branch, effective_date } = filteredPolicies[0].terms[0];
      fetchPolicyDecs(
        value,
        company_branch,
        formatDate(effective_date, "YYYY-MM-DD")
      );
    }
  };

  useEffect(() => {
    if (policies && policies.length === 1) {
      if (selectedPolicyNumber !== policies[0].number) {
        setSelectedPolicyNumber(policies[0].number);
        setEnablePolicyDropdown(false);
      }
    }
  }, [policies, selectedPolicyNumber]);

  useEffect(() => {
    fetchMyPoliciesList();

    if (match?.params?.accountNumber) {
      setSelectedPolicyNumber(match.params.accountNumber);
      setFetchPolicyDecsInitially(true);
    }
  }, [fetchMyPoliciesList, match.params.accountNumber]);

  useEffect(() => {
    const selectedPolicy = policies?.filter(
      _ => _?.number === selectedPolicyNumber
    )[0];
    if (
      !error &&
      !isLoading &&
      !coverageOptionsData &&
      policies &&
      policies.length === 1
    ) {
      const { company_branch, effective_date } = policies[0].terms[0];

      fetchPolicyActivity(policies[0].number);
      fetchPolicyDecs(
        policies[0].number,
        company_branch,
        formatDate(effective_date, "YYYY-MM-DD")
      );
    }

    if (
      !error &&
      selectedPolicyNumber &&
      fetchPolicyDecsInitially &&
      !isLoading &&
      !coverageOptionsData &&
      policies &&
      !!selectedPolicy
    ) {
      setFetchPolicyDecsInitially(false);

      const policyTerm = policies.filter(
        policy => policy.number === selectedPolicyNumber
      )[0].terms[0];

      const companyBranch = policyTerm.company_branch;
      const effectiveDate = policyTerm.effective_date;

      fetchPolicyActivity(selectedPolicyNumber);
      fetchPolicyDecs(
        selectedPolicyNumber,
        companyBranch,
        formatDate(effectiveDate, "YYYY-MM-DD")
      );
    }
  }, [
    coverageOptionsData,
    error,
    fetchPolicyActivity,
    fetchPolicyDecs,
    fetchPolicyDecsInitially,
    isLoading,
    policies,
    selectedPolicyNumber
  ]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <ContentHeader>{Translations.my_policy.title}</ContentHeader>
      {!hasPolicies(policies) ? (
        <HelpDeskMessage before="There is currently no Policy Activity available for your policy or account. If you feel this is a technical error," />
      ) : (
        <div>
          <div>
            <SelectNonForm
              id="policyNumber"
              name="policyNumber"
              label="Select Policy"
              placeholder="Select Policy"
              options={policyOptions}
              onChange={handleDropDownChange}
              value={selectedPolicyNumber}
              disabled={!enablePolicyDropdown}
              isClearable={false}
              size="lg"
            />
          </div>
          <div>
            {selectedPolicy && (
              <PolicyInfo
                key={selectedPolicy.number}
                policy={selectedPolicy}
                policyNumber={selectedPolicyNumber}
              />
            )}
          </div>
          {enableViewAllFormsButton.includes("true") &&
            selectedPolicyNumber !== "" &&
            selectedPolicy &&
            selectedPolicy.origin === "ufg_core" && (
              <div className="view-all-forms-group">
                {showLargePolicyMessage && (
                  <span className="large-policy-message">
                    {
                      Translations.my_policy.lines_of_business.view_all_forms
                        .large_policy_message
                    }
                  </span>
                )}
                <div className="view-all-forms-group__button">
                  <DocumentLink
                    color="primary"
                    pdfUri={`${window.env.REACT_APP_POLICYHOLDER_ACCOUNTS_EAPI_MULE_4_URI}/policies/${selectedPolicyNumber}/documents`}
                    filename="My_Policy.pdf"
                    timeout={FIVE_MINUTES}
                    onClick={trackAnalytics}
                  >
                    {
                      Translations.my_policy.lines_of_business.view_all_forms
                        .button
                    }
                  </DocumentLink>
                </div>
              </div>
            )}
          <div className="lines-of-business">
            <div>
              {selectedPolicy &&
                selectedPolicy.origin === "ufg_core" &&
                coverageOptionsData &&
                coverageOptionsData[selectedPolicyNumber] && (
                  <LinesOfBusiness
                    policyNumber={selectedPolicyNumber}
                    coverageOptions={coverageOptionsData[selectedPolicyNumber]}
                    selectedPolicy={selectedPolicy}
                  />
                )}
              {selectedPolicy &&
                selectedPolicy.origin === "ufg_core" &&
                !coverageOptionsData && (
                  <div className="lines-of-business__error">
                    <CustomErrorView
                      error={error}
                      errorLocationInTranslation={
                        Translations.my_policy.lines_of_business.error_codes
                      }
                      handleDeskErrorCodes={["500"]}
                    />
                  </div>
                )}
            </div>
            <div>
              {selectedPolicy && (
                <PolicyActivityContainer
                  key={selectedPolicy.number}
                  policyNumber={selectedPolicyNumber}
                  origin={selectedPolicy.origin}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

MyPolicyContainer.propTypes = {
  myPolicyData: PropTypes.object,
  fetchMyPoliciesList: PropTypes.func.isRequired,
  fetchPolicyDecs: PropTypes.func.isRequired,
  fetchPolicyActivity: PropTypes.func.isRequired,
  match: PropTypes.object
};

const mapStateToProps = state => ({
  myPolicyData: {
    policies: state.myPolicyData.policies,
    coverageOptionsData: state.myPolicyData.coverageOptionsData,
    error: state.myPolicyData.error,
    isLoading: state.myPolicyData.isLoading
  }
});

const mapDispatchToProps = {
  fetchMyPoliciesList,
  fetchPolicyActivity,
  fetchPolicyDecs
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPolicyContainer);
