import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../translations";

const propTypes = {
  account: PropTypes.object.isRequired
};

const { alert_options } = Translations.payments_alerts.paperless_billing;

const PaperlessDetail = ({ account }) => {
  return (
    <div className="paperless-detail collapse in">
      <table className="paperless-detail__table">
        <thead className="paperless-detail__header">
          <tr>
            <th className="paperless-detail-item__number">Policy</th>
            <th className="paperless-detail-item__type">Policy Type</th>
            <th className="paperless-detail-item__alert">Paperless Billing</th>
            <th className="paperless-detail-item__alert">
              Payment Email Alerts
            </th>
            <th className="paperless-detail-item__alert">
              Policy Email Alerts
            </th>
          </tr>
        </thead>
        <tbody>
          {account.policies.map(policy => {
            return [
              <tr key={policy} className="paperless-detail__row">
                <td className="paperless-detail-item__number">
                  {policy.number}
                </td>
                <td className="paperless-detail-item__type">
                  {Translations.policy_types[policy.policy_type] ??
                    policy.policy_type}
                </td>
                <td className="paperless-detail-item__alert">
                  {alert_options[policy.paperless_bill]}
                </td>
                <td className="paperless-detail-item__alert">
                  {alert_options[policy.bill_email_alert]}
                </td>
                <td className="paperless-detail-item__alert">
                  {alert_options[policy.policy_email_alert]}
                </td>
              </tr>
            ];
          })}
        </tbody>
      </table>
    </div>
  );
};

PaperlessDetail.propTypes = propTypes;
export default PaperlessDetail;
