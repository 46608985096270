import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Translations } from "./translations";
import * as routes from "../constants/routes";
import { v4 } from "uuid";
import DocumentService from "../services/documentService";
import { getPdf } from "../services/policyholderService";
import { LOADING_DOCUMENT_ROUTE } from "../constants/routes";
import { Button } from "@ufginsurance/ui-kit";
import InfoModal from "./modals/InfoModal";
import HelpDeskMessage from "../help-desk/HelpDeskMessage";

const QuickLinks = ({ links }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const timeout = parseInt(window.env.REACT_APP_API_TIMEOUT, 10);

  const handleGetPdf = useCallback(
    item => {
      setIsLoading(true);

      const documentRenderer = new DocumentService();
      documentRenderer.initializeRenderer(LOADING_DOCUMENT_ROUTE);

      const key = "get:" + item.url;
      sessionStorage.setItem(key, Date.now());

      getPdf(item.url, { timeout }).then(
        response => {
          documentRenderer.saveOrOpenDocument(
            response.data,
            item.display_key + ".pdf"
          );
          setIsLoading(false);
        },
        e => {
          documentRenderer.clearRenderer();
          setIsLoading(false);
          setIsError(e);
        }
      );
    },
    [timeout]
  );

  const renderErrorDisplay = () => {
    return (
      <InfoModal
        hideModal={() => setIsError(false)}
        title="Error"
        message={
          <HelpDeskMessage before="There was an error loading your document. For further assistance," />
        }
      />
    );
  };

  const goTo = item => {
    if (item.response_type !== "blob") window.location = item.url;
    else handleGetPdf(item);
  };

  const staticLinks = [
    {
      url: `${window.env.REACT_APP_PUBLICSITE_BASEURL}/report-a-claim`,
      title: "Report a Claim"
    },
    { url: routes.AUTO_ID_CARDS_ROUTE, title: "Get Auto ID Cards" },
    { url: routes.BILLING_ACTIVITY_ROUTE, title: "View Billing Activity" },
    { url: routes.POLICY_ROUTE, title: "View Policy History" },
    { url: routes.INSTALLMENT_ROUTE, title: "View Installment Schedule" },
    { url: routes.BILLING_STATEMENTS_ROUTE, title: "View Statements" },
    { url: routes.PAYMENT_OPTIONS, title: "Payment Options" }
  ];

  const items =
    !links || links.length === 0 ? staticLinks : [...staticLinks, ...links];

  return (
    <div className="card">
      {isError && renderErrorDisplay()}
      <div>
        <h3 className="card__header">{Translations.quick_links.title}</h3>
        <div className="line-container">
          <span className="red-line" />
          <span className="grey-line" />
        </div>
      </div>
      <div className="quick-links">
        {items.map(item => (
          <div key={item?.display_key || v4()}>
            <Button
              onClick={_ => goTo(item)}
              spinner={isLoading}
              variant="tertiary"
              isLink
            >
              {item?.title ||
                Translations.quick_links.link_keys[item.display_key]}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

QuickLinks.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default QuickLinks;
