import React, { Component } from "react";
import PropTypes from "prop-types";
import { Translations } from "./translations/Translations";
import AutoId from "./account-summary/AutoId";
import orderBy from "lodash.orderby";

class AutoIdCardsTable extends Component {
  static propTypes = {
    autoIdCardAccounts: PropTypes.arrayOf(PropTypes.object).isRequired
  };

  state = {};

  orderAccountsAndPolicies(autoIdCardAccounts) {
    let orderedAccounts = orderBy(
      autoIdCardAccounts,
      policy => policy.account_number,
      "desc"
    );
    orderedAccounts = orderedAccounts.reduce((acc, policy) => {
      const filteredAccount = acc.filter(
        _policy => _policy.account_number === policy.account_number
      );

      if (filteredAccount.length === 0) {
        acc.push({
          account_number: policy.account_number,
          policies: [{ ...policy }]
        });
      } else {
        filteredAccount[0].policies.push({
          policy_number: policy.policy_number,
          policy_nickname: policy.policy_nickname,
          policy_type: policy.policy_type,
          auto_id_link: policy.auto_id_link
        });
      }
      return acc;
    }, []);
    return orderedAccounts.map(account => {
      orderBy(account.policies, policy => policy.policy_number, "desc");
      return account;
    });
  }

  render() {
    const { autoIdCardAccounts } = this.props;
    return (
      <table className="table">
        <thead>
          <tr>
            <td key="1">
              {Translations.quick_links.get_id_cards.header_names.account}
            </td>
            <td key="2">
              {Translations.quick_links.get_id_cards.header_names.policy}
            </td>
            <td key="3">
              {Translations.quick_links.get_id_cards.header_names.ID_card}
            </td>
          </tr>
        </thead>
        <tbody>
          {this.orderAccountsAndPolicies(autoIdCardAccounts).map(account =>
            account.policies.map(policy => (
              <tr key={policy.policy_number}>
                <td>{account.account_number}</td>
                <td>
                  {`${policy.policy_number} - ${
                    policy.policy_nickname ||
                    (Translations.policy_types[policy.policy_type] ??
                      policy.policy_type)
                  }`}
                </td>
                <td>
                  <AutoId autoIdLink={policy.auto_id_link} />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    );
  }
}

export default AutoIdCardsTable;
